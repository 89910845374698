@import "nav";
@import "menu_utility";
.slider-menu {
	background-color: $white;

	nav a {
		font-size: 12px;
		color: #131415;
		letter-spacing: normal;
	}
}

.menu-toggle{
	span{
		background: $mobile-menu-bar-color;
	}
}

body.header-sticky-desktop .slider-menu{
	nav a {
		font-weight: 600;
	}
}

.header-menu-scroll {
	background-color: $white;
}

nav.header-menu-mobile-tablet a{
	color: #131415;
	font-weight: 600;
	font-size: 12px;
	letter-spacing: normal;
}

@media screen and (min-width: 1024px) {
	.ucp2-cart-icon,
	.ucp2-cart-with-item-icon{
		background-image: url(../../../../images/cart_black.svg);
	}
}

//Header-Promo-Banner
.header-promo-banner {
	.header-promotion {
		@include WorkSansMedium($WorkSans);
	}
}

//Top-banner

.header-offers {
	.offer-flyout-main {
    	span {
    		&.offers {
			    @include WorkSansMedium($WorkSans);
    		}
    	}
	}
	.offers-flyout-banner {
		.viewall-offers {
			a {
			    @include WorkSansSemiBold($WorkSans);
			}
		}
	}
}

.header-secondary {
    .header-search {
		.quick-order{
			.catalogOrderIcon{
				.cqo_text{
					color: $spicy-pink;
					@include WorkSansMedium($WorkSans);
				}
			}
		}

		input[type="text"] {
			@media screen and (min-width: 1024px) {
				@include WorkSansMedium($WorkSans);
			}
		}

		button {
			&:hover {
				color: $citrus;
			}
		}
	}
}

//Header-promo-bottom
.header-promo-bottom {
    .promo-banner {
	   @include WorkSansMedium($WorkSans);
		
	    a {
		    color: $geraldine;
		    border-bottom: 1px solid $geraldine;
    	}
    }
}

@media screen and (min-width:768px) and (max-width:1023px) {
	.header-secondary {
		.header-search {
			input[type="text"] {
				font-family: $WorkSans;
			}
		}
	}
}

@media screen and (max-width:767px) {
	.top-menu-utility {
		.menu-utility {
			.menu-utility-user {
				#mini-cart {
				    .mini-cart-total {
				    	span {
							@include WorkSansMedium($WorkSans);
				    		&.minicart-quantity {
				    			background-color: $geraldine;
				    		}
				    	}
				    }
				}
			}
		}
	}

	.top-banner {
		.header-mian-banner {
			.header-secondary {
			    .header-search {
    				&.active {
    					#search-suggestions {
    						.search-suggestion-wrapper {
    							.suggested-phrase {
    								@include WorkSansMedium($WorkSans);
    							}

    							.search-phrase {
    								font-family: $Helvetica;
    								a {
    									span {
    										font-family: $HelveticaBold;
    									}
    								}
    							}

    							.phrase-suggestions {
    								.hitgroup {
    									.hit {
											@include WorkSansBold($WorkSans);
    									}
    								}
    							}
    						}
    					}
    				}
			    }
			}
		}
	}

	.slider-menu {
		#navigation {
			.scroll-wrapper {
				.registered-user {
					.my-account {
						&.heading {
							@include WorkSansMedium($WorkSans);
						    background: $wild-sand;
						}
					}
					.account-logout {
						a {
							@include WorkSansSemiBold($WorkSans);
						}
					}
				}
			}
		}
	}
}

//Checkout Header Sizing
.pt_checkout {
    #header {
        .primary-logo {
            a {
				width: 220px;
				max-height: 28px;
            }
        }
    }
}
@media screen and (max-width: 767px) {
	.pt_checkout {
		#header {
			.header-mian-banner {
				.help-click {
					top: 35px;
				}
			}
		} 
	}
}

@media screen and (min-width: 1024px){
	.menu-category .has-sub-menu, .menu-category .no-flyout a{
		letter-spacing: normal;
	}
}

@media only screen and (max-width: 767px){
	.brands-header{
		.brands-header-active-mobile{
			.brand-menu-image{
				img{
					height: 35.2px;
				}
			}
		}
	}
}